import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ISignaturePlaceholderImg,
  ISignaturePlaceholderValueImgAdditional,
  TImageDimensionDataMode,
  ImageDimensionWithMode,
  WhichImage,
  TextOrGraphic,
  ISignaturePlaceholderValueImg,
  BooleanString
} from 'src/app/model/interfaces/signature.interface';
import { ISignaturePlaceholderTypeModalOptions } from 'src/app/services/alert/alert-service.interface';
import { AlertService } from 'src/app/services/alert/alert.service';
import { SignatureHelperService } from 'src/app/services/signature-helper/signature-helper.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { take } from 'rxjs/operators';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { DefaultBoxComponent } from '@molecules/boxes/default/default.component';
import { UploadImageButtonComponent } from '@molecules/buttons/upload-image-button/upload-image-button.component';
import { MtColorPickerComponent } from '@atoms/inputs/color-picker/color-picker.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgStyle } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { UtilService } from '@services/util/util.service';

const DEFAULT_WIDTH = 50;
const DEFAULT_IMAGE = 'https://app.mailtastic.de/api/images/default/User.png';

@Component({
  selector: 'mt-signature-placeholder-type-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    CustomModalComponent,
    DefaultBoxComponent,
    FormsModule,
    MtColorPickerComponent,
    NgIf,
    NgStyle,
    ReactiveFormsModule,
    TranslocoModule,
    UploadImageButtonComponent
  ]
})
export class SignaturePlaceholderTypeImageModalComponent {
  heading = '';
  subHeading = '';
  readImageFormData!: ISignaturePlaceholderImg;
  modalDeleteImageRef!: NgbModalRef;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('modalDeleteImage') modalDeleteImage!: TemplateRef<any>;

  /**
   * The dataset id.
   */
  @Input() datasetId?: string;

  /**
   * The employee id.
   */
  @Input() employeeId?: string;

  /**
   * Sets up the modal and patches the data from `modalOptions` to the corresponding fields.
   */
  @Input() set modalOptions(modalOptions: ISignaturePlaceholderTypeModalOptions) {
    const formData = modalOptions.formData as ISignaturePlaceholderImg;
    this.heading = modalOptions.heading ?? '';
    this.subHeading = modalOptions.subheading ?? '';
    this.readImageFormData = formData;
    this.patchForm(formData, modalOptions?.global);
  }

  advancedSettingsForm = new FormGroup({
    altText: new FormControl('', { nonNullable: true }),
    defaultImage: new FormControl('', { nonNullable: true }),
    global: new FormControl('false', { nonNullable: true }), // should photo be used for all employess
    imageDimensionHeight: new FormControl<number>(DEFAULT_WIDTH, { nonNullable: true }),
    imageDimensionMode: new FormControl('default', { nonNullable: true }),
    imageDimensionWidth: new FormControl<number>(DEFAULT_WIDTH, { nonNullable: true }),
    linkColor: new FormControl('', { nonNullable: true }),
    linkText: new FormControl('', { nonNullable: true }),
    photoUrl: new FormControl('', { nonNullable: true }),
    tag: new FormControl('', { nonNullable: true }), // {disabled: true}),
    textOrGraphic: new FormControl<TextOrGraphic>('image', { nonNullable: true }),
    underline: new FormControl<BooleanString>('true', { nonNullable: true }),
    url: new FormControl('', { nonNullable: true }),
    useForAllProfiles: new FormControl(false, { nonNullable: true }), // should photo be applied to all datasets
    whichImage: new FormControl<WhichImage>('default', { nonNullable: true })
  });

  constructor(
    private alert: AlertService,
    private employeeService: EmployeeService,
    public modal: NgbActiveModal,
    public signatureHelper: SignatureHelperService,
    private utilService: UtilService
  ) {}

  //#region PUBLIC
  /**
   * Gets either the image src or the default image.
   * @returns image src
   */
  getImageSrc(): string {
    const isSrc = this.getImageFormImageAsString();
    if (isSrc !== '') {
      return isSrc;
    } else {
      return DEFAULT_IMAGE;
    }
  }

  /**
   * Sets the image.
   * @param image - the image
   */
  setImage(image: HTMLImageElement): void {
    if (this.signatureHelper.isImg(this.readImageFormData)) {
      if (this.signatureHelper.isImgValue(this.readImageFormData.value)) {
        // saves the long-term data
        // used to trigger the backend to update the image
        this.readImageFormData.value.image = {
          $ngfDataUrl: image.src,
          $ngfWidth: image.width,
          $ngfHeight: image.height
        };
        // used for future scaling
        this.readImageFormData.value.initialdimension = { height: image.height, width: image.width };

        // saves the temporary data
        this.advancedSettingsForm.patchValue({
          photoUrl: image.src,
          imageDimensionHeight: image.height,
          imageDimensionWidth: image.width,
          imageDimensionMode: 'default'
        });
      }
    }
  }

  /**
   * Sets the link color.
   * @param color - the color
   */
  setLinkColor(color: string): void {
    this.advancedSettingsForm.patchValue({
      linkColor: color
    });
  }

  /**
   * Resets the image value to an empty string, except if the image is already uploaded
   * @param imageValue - The value of the image
   */
  resetImageValue(imageValue: string): void {
    if (imageValue.startsWith('dummy')) {
      this.advancedSettingsForm.patchValue({
        photoUrl: ''
      });
    }
  }

  /**
   * Saves the values of the form modal.
   */
  saveValue(): void {
    if (
      !this.isGraphicUploaded(
        this.advancedSettingsForm.controls.whichImage.value,
        this.advancedSettingsForm.controls.photoUrl.value,
        this.advancedSettingsForm.controls.textOrGraphic.value
      )
    ) {
      return;
    }
    this.readImageFormData.value = structuredClone(this.readImageFormData.value) as ISignaturePlaceholderValueImg;

    // REMARK: this property should not exist
    if ((this.readImageFormData.value as any)?.styles) {
      delete (this.readImageFormData.value as any).styles;
    }

    // assigns input values
    this.readImageFormData.value.url = this.advancedSettingsForm.controls.url.value;

    // assigns radio button values
    this.readImageFormData.value.showAs = this.advancedSettingsForm.controls.textOrGraphic.value;

    // as image
    if (this.advancedSettingsForm.controls.textOrGraphic.value === 'image') {
      // scale image or save default
      this.readImageFormData.imgdimension = this.utilService.scaleImage(
        Number(this.readImageFormData?.value?.initialdimension?.width ?? DEFAULT_WIDTH),
        Number(this.readImageFormData?.value?.initialdimension?.height ?? DEFAULT_WIDTH),
        this.advancedSettingsForm.controls.imageDimensionWidth.value,
        this.advancedSettingsForm.controls.imageDimensionMode.value as TImageDimensionDataMode
      );

      // assigns input values
      this.readImageFormData.value.altText = this.advancedSettingsForm.controls.altText.value;

      // assigns radio button values
      this.readImageFormData.value.whichImage = this.advancedSettingsForm.controls.whichImage.value;
    }

    // as text
    if (this.advancedSettingsForm.controls.textOrGraphic.value === 'text') {
      // assigns input values
      this.readImageFormData.value.linkText = this.advancedSettingsForm.controls.linkText.value;
      this.readImageFormData.value.linkcolor = this.advancedSettingsForm.controls.linkColor.value;

      // add empty styles object so it is defined, when initializing the fields like employee google plus, styles object was undefined
      if (!this.readImageFormData.styles) {
        this.readImageFormData.styles = {};
      }

      this.readImageFormData.styles.linkcolor = this.advancedSettingsForm.controls.linkColor.value;
      this.readImageFormData.styles.underline = this.advancedSettingsForm.controls.underline.value;

      // assigns radio button values
      this.readImageFormData.value.underline = this.advancedSettingsForm.controls.underline.value;
    }

    this.modal.close(this.readImageFormData);
  }
  //#endregion

  //#region TEMPLATE DELETE IMAGE
  /**
   * Deletes the user image for either all or just selected profile.
   * @param whoToDelete datasetId | 'all'
   */
  deleteUserImage(whoToDelete?: string): void {
    if (!this.employeeId || !whoToDelete) {
      return;
    }
    this.employeeService
      .deleteUserImage(this.employeeId, whoToDelete)
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          this.modalDeleteImageRef.close('refresh');
        } else {
          this.modalDeleteImageRef.close('errored');
          void this.alert.defaultErrorMessage(this.alert.translateDataNotLoaded());
        }
      });
  }
  //#endregion

  //#region PRIVATE
  /**
   * If user selected own graphic as a source, the image needs to be uploaded
   * @param whichImage - The value of the whichImage property
   * @param photoUrl - The value of the image
   * @param textOrGraphic - The value of the textorgraphic property
   * @returns true or false depending if we uploaded the graphic or not
   */
  private isGraphicUploaded(whichImage: WhichImage, photoUrl: string, textOrGraphic: TextOrGraphic): boolean {
    if (textOrGraphic === 'image' && whichImage === 'own' && photoUrl === '') {
      void this.alert.defaultErrorMessage(
        this.alert.translate('please_upload_image'),
        this.alert.translate('error_message_sftp_sync_hl')
      );
      return false;
    }
    return true;
  }

  /**
   * Gets the image value as either an object containing a base64 value or a string containing a link
   * @param imageValue - value of the image
   * @returns object with a base64 value or a just a link
   */
  private getImageValue(imageValue: string): string | ISignaturePlaceholderValueImgAdditional {
    if (imageValue.startsWith('data:image')) {
      return {
        $ngfDataUrl: imageValue
      };
    }
    return imageValue;
  }

  /**
   * Gets the string value of the saved value for the image.
   * @returns image src
   */
  private getImageFormImageAsString(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const isString = this.advancedSettingsForm.controls.photoUrl.value;
    if (typeof isString === 'string') {
      return isString;
    } else {
      return '';
    }
  }

  /**
   * Patches the form.
   * @param formData - (optional) form data to patch
   * @param isGlobal - if is global
   */
  private patchForm(formData?: ISignaturePlaceholderImg, isGlobal = false): void {
    const useForAllProfilesValue =
      this.subHeading.toLowerCase() === 'unternehmen' || this.subHeading.toLowerCase() === 'company';
    this.advancedSettingsForm.patchValue({
      altText: formData?.value?.altText ?? '',
      defaultImage: formData?.defaultImage ?? '',
      global: String(isGlobal),
      imageDimensionHeight: Number(formData?.imgdimension?.height) ?? 50,
      imageDimensionMode: formData?.imgdimension?.mode ?? 'default',
      imageDimensionWidth: Number(formData?.imgdimension?.width) ?? 50,
      linkColor: formData?.styles?.linkcolor ?? '',
      linkText: formData?.value?.linkText ?? '',
      photoUrl: typeof formData?.value?.image === 'string' ? formData?.value?.image : '',
      tag: formData?.tag ?? '',
      textOrGraphic: formData?.value?.showAs ?? 'image',
      underline: formData?.styles?.underline ?? 'true',
      url: formData?.value?.url ?? '',
      useForAllProfiles: useForAllProfilesValue,
      whichImage: formData?.value?.whichImage ?? 'default'
    });
    this.advancedSettingsForm.controls.tag.disable();
  }
  //#endregion
}
