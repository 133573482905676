<div class="max_100" *ngIf="authorizationScope$ | async as authorizationScope">
  <div class="container-fluid">
    <!-- Fisrt row -->
    <div class="row row-eq-height-lg margin-hor-0">
      <!-- Start section -->
      <div class="baseonecolumn padding-bottom0">
        <div class="headline format0">
          <span transloco="start_start"></span>
        </div>
      </div>

      <!-- Welcome section -->
      <div class="col-lg-3 col-12 mb-4">
        <div class="panel custompanel h-100">
          <div class="campaign-list-panel-body p-30">
            <h4 class="font-weight-bold">
              <span transloco="start_welcome"></span>
              <span data-hj-suppress>
                <!-- TODO: when serialization of data stored on local storage is properly implemented remove check authorizationScope.accountData.firstName !== 'null'  -->
                {{ authorizationScope.accountData.firstName && authorizationScope.accountData.firstName !== 'null' ? authorizationScope.accountData.firstName : authorizationScope.accountData.email }}
              </span>
            </h4>
            <h2
              data-hj-suppress
              class="font-weight-bold"
              *ngIf="authorizationScope.accountData.companyName"
            >
              {{
                authorizationScope.accountData.companyName | textTruncate : 20
              }}
            </h2>
            <small
              ><span transloco="loggendinas"></span><br />
              {{ authorizationScope.accountData.email }}
            </small>
            <div *ngIf="sectionData$ | async as sectionData">
              <img
                (click)="
                  openPlayVideoModal(
                    'dashboard_welcome_video',
                    'dashboard_welcome_btn',
                    sectionData.steps[0]
                  )
                "
                class="placehoder cursor-pointer"
                src="assets/images/getStartedGuide/guide_1_1_{{
                  translate.getActiveLang()
                }}.png"
                alt="guide tour image"
              />
            </div>
            <span class="infobar-icons d-flex install-sig"
              ><mt-svg
                src="assets/images/svgs/video.svg"
                class="mt-blau large-svg install-sig-svg"
              ></mt-svg
              ><a
                (click)="
                  openPlayVideoModal(
                    'dashboard_welcome_video',
                    'dashboard_welcome_btn'
                  )
                "
                class="links"
                transloco="dashboard_welcome_btn"
              ></a
            ></span>
          </div>
        </div>
      </div>

      <!-- Quickstart section -->
      <div class="col-lg-3 col-12 mb-4">
        <div class="panel custompanel h-100">
          <div class="campaign-list-panel-body p-30">
            <div class="mb-45">
              <h4 class="font-weight-bold" transloco="start_quickstart"></h4>
              <p transloco="dashboard_quickstart_sl"></p>
            </div>

            <mt-button
              [routerLink]="['/employees/create']"
              class="w-100 mb-2"
              color="primary"
              icon="plus"
              klass="w-100"
              text="create_employee"
            ></mt-button>

            <mt-button
              (click)="openCreateCampaign()"
              class="w-100 mb-2"
              color="primary"
              icon="plus"
              klass="w-100"
              text="Createcampaign"
            ></mt-button>

            <mt-button
              (click)="openCreateEvent()"
              class="w-100 mb-2"
              color="primary"
              icon="plus"
              klass="w-100"
              text="create_event"
            ></mt-button>

            <mt-button
              [routerLink]="['/signatures/create']"
              class="w-100"
              color="primary"
              icon="plus"
              klass="w-100"
              text="Createasignature"
            ></mt-button>
          </div>
        </div>
      </div>

      <!-- Continue here section -->
      <div
        class="col-lg-6 col-12 mb-4"
        *ngIf="guideService.loadGuideData$ | async as guideData"
      >
        <div class="panel custompanel blue-bg h-100">
          <div class="campaign-list-panel-body p-30">
            <h3
              class="font-weight-bold text-white mt-md-2 mt-0"
              transloco="dashboard_continuehere_hl"
            ></h3>
            <p class="text-white" transloco="dashboard_continuehere_sl"></p>
            <div class="inner_div content-section bg-white">
              <!-- Progress bar -->
              <ngb-progressbar
                type="primary"
                [value]="calculateProgress(guideData.data.steps)"
                height="6px"
                class="dashboard-guide-progress-bar"
              ></ngb-progressbar>
              <div class="pd-rem-2 p-3">
                <div class="d-flex flex-md-row flex-column">
                  <div class="flex-grow-1 ms-md-3">
                    <div [ngClass]="{ invisible: !guideData.stepTitle }">
                      <small
                        class="mar-left-35"
                        transloco="dashboard_continuehere_nextstep"
                      ></small>
                      <div class="d-flex">
                        <mt-svg
                          class="guide-svg"
                          src="{{ guideData.data.icon }}"
                          size="18"
                        ></mt-svg>
                        <h4 class="mt-2 font-weight-bold">
                          {{ guideData.stepTitle | transloco }}
                        </h4>
                      </div>
                      <p class="mar-left-35">
                        Section: {{ guideData.data.title | transloco }}
                      </p>
                    </div>
                    <mt-button
                      *ngIf="guideData.stepTitle"
                      [routerLink]="['/getstartedguide']"
                      [queryParams]="{ section: guideData.data.title }"
                      class="guide-btn mar-left-35"
                      color="primary"
                      text="dashboard_continuehere_btn"
                    ></mt-button>
                    <mt-button
                      *ngIf="!guideData.stepTitle"
                      [routerLink]="['/getstartedguide']"
                      class="guide-btn completed-guide"
                      color="primary"
                      text="openguide_btn"
                    ></mt-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Second row -->
    <div class="row row-eq-height-lg margin-hor-0">
      <!-- Help section -->
      <div class="col-lg-3 col-12 mb-4">
        <div class="panel custompanel h-100">
          <div class="campaign-list-panel-body p-30">
            <h4 class="font-weight-bold" transloco="start_help"></h4>
            <div class="d-flex mb-4 mt-4">
              <div class="mar-top-1 install-sig-svg">
                <mt-svg
                  class="mt-blau large-svg vertical-align-middle install-sig-svg"
                  size="18"
                  src="assets/images/svgs/tour.svg"
                ></mt-svg>
              </div>
              <div class="flex-grow-1">
                <span class="infobar-icons d-block mar-top-3">
                  <a
                    class="links text-decoration-none"
                    transloco="dashboard_help_viewsetupguide"
                    [routerLink]="['/getstartedguide']"
                  ></a>
                </span>
                <small transloco="dashboard_help_viewsetupguide_sl"></small>
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="mar-top-1 install-sig-svg">
                <mt-svg
                  class="mt-blau large-svg vertical-align-middle install-sig-svg"
                  size="18"
                  src="assets/images/svgs/article.svg"
                ></mt-svg>
              </div>
              <div class="flex-grow-1">
                <span class="infobar-icons d-block mar-top-3">
                  <a
                    class="links text-decoration-none"
                    transloco="dashboard_help_viewhelpcenter"
                    href="{{ 'sidebar_settings_help' | transloco }}"
                    target="_blank"
                  >
                  </a
                ></span>
                <small transloco="dashboard_help_viewhelpcenter_sl"></small>
              </div>
            </div>
            <div class="d-flex mb-4">
              <div class="mar-top-1 install-sig-svg">
                <mt-svg
                  class="mt-blau large-svg vertical-align-middle install-sig-svg"
                  src="assets/images/svgs/video.svg"
                  size="18"
                ></mt-svg>
              </div>
              <div class="flex-grow-1">
                <span class="infobar-icons d-block mar-top-3">
                  <a
                    class="links text-decoration-none"
                    transloco="dashboard_help_viewvideotutorials"
                    (click)="
                      openPlayVideoModal(
                        'dashboard_help_viewvideotutorials_link',
                        'dashboard_help_viewvideotutorials'
                      )
                    "
                  >
                  </a>
                </span>
                <small transloco="dashboard_help_viewvideotutorials_sl"></small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Install signature section -->
      <div class="col-lg-3 col-12 mb-4">
        <div class="panel custompanel h-100">
          <div class="campaign-list-panel-body p-30">
            <h4
              class="font-weight-bold"
              transloco="dashboard_installsignature_hl"
            ></h4>
            <div class="mtop-30 mb-2rem">
              <img
                (click)="
                  openPlayVideoModal(
                    'dashboard_installsignature_watchvideo_video',
                    'dashboard_installsignature_hl'
                  )
                "
                class="placehoder cursor-pointer"
                src="assets/images/signatures/install-signature-modal/thumbnails_installsignature_cognism.png"
                alt="thumbnails_installsignature_cognism"
              />
            </div>
            <span class="infobar-icons d-flex install-sig"
              ><mt-svg
                src="assets/images/svgs/video.svg"
                class="mt-blau large-svg install-sig-svg"
              ></mt-svg
              ><a
                (click)="
                  openPlayVideoModal(
                    'dashboard_installsignature_watchvideo_video',
                    'dashboard_installsignature_hl'
                  )
                "
                class="links"
                transloco="dashboard_installsignature_watchvideo_btn"
              ></a
            ></span>
            <span class="infobar-icons d-flex install-sig"
              ><mt-svg
                src="assets/images/svgs/tour.svg"
                class="mt-blau large-svg install-sig-svg"
              ></mt-svg
              ><a
                class="links"
                transloco="dashboard_installsignature_setupguide_btn"
                (click)="
                  redirectToSignatureGuide(
                    authorizationScope.accountData.email,
                    authorizationScope.data.userId
                  )
                "
              ></a
            ></span>
          </div>
        </div>
      </div>

      <!-- Invite colleagues section -->
      <div class="col-lg-3 col-12 mb-4">
        <div class="panel custompanel h-100">
          <div class="campaign-list-panel-body p-30">
            <h4
              class="font-weight-bold"
              transloco="dashboard_invitecolleagues_hl"
            ></h4>
            <p
              transloco="dashboard_invitecolleagues_sl"
              class="invite-colleagues"
            ></p>

            <mt-button
              class="w-100 mb-2"
              color="danger"
              klass="w-100"
              text="dashboard_invitecolleagues_m365_btn"
              (click)="openo365Integration()"
            ></mt-button>

            <mt-button
              class="w-100"
              color="legacy_primary"
              klass="w-100"
              text="dashboard_invitecolleagues_google_btn"
              [routerLink]="['/googlesync']"
            ></mt-button>
          </div>
        </div>
      </div>

      <!-- Feedback section -->
      <div class="col-lg-3 col-12 mb-4">
        <div class="panel custompanel h-100">
          <div class="feedback-panel campaign-list-panel-body p-30">
            <h4 class="font-weight-bold" transloco="dashboard_feedback_hl"></h4>
            <p transloco="dashboard_feedback_sl"></p>
            <textarea
              class="blank-div"
              [(ngModel)]="feebackMessage"
              name="feebackMessage"
            ></textarea>
            <mt-button
              (click)="submitFeedback()"
              [disabled]="!feebackMessage"
              class="feedback-btn"
              color="primary"
              text="Sendfeedback"
              type="submit"
            ></mt-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
